const BASE_URL_ACCOUNT = `${process.env.VUE_APP_API_URL_ACCOUNT}/dashboard`
const BASE_URL_ACCOUNT_MOBILE = `${process.env.VUE_APP_API_URL_ACCOUNT}/v1`
const BASE_URL_LOYALITY = `${process.env.VUE_APP_API_URL_LOYALITY}/dashboard`
const BASE_URL_CORE = `${process.env.VUE_APP_API_URL_CORE}/main/core`
const BASE_URL_CORESTORE = `${process.env.VUE_APP_API_URL_CORESTORE}`
const BASE_URL_STORE = `${process.env.VUE_APP_API_URL_STORE}/dashboard`
const BASE_URL_STORE_PUBLIC = `${process.env.VUE_APP_API_URL_STORE}`
const BASE_URL_ORDER = `${process.env.VUE_APP_API_URL_ORDER}/dashboard`
const BASE_URL_PROMOTION = `${process.env.VUE_APP_API_URL_PROMOTION}`
const BASE_URL_ANALYTIC = `${process.env.VUE_APP_API_URL_ANALYTIC}/dashboard/pandora`
const BASE_URL_ANALYTIC2 = `${process.env.VUE_APP_API_URL_ANALYTIC}/dashboard`
const BASE_URL_LOGGER = `${process.env.VUE_APP_API_URL_LOGGER}`
const BASE_URL_REPORT = `${process.env.VUE_APP_API_PANDORA_SERVICES}/api`
const BASE_URL_CHAT = `${process.env.VUE_APP_API_URL_CHAT}/dashboard`
const BASE_URL_PANDORA = `${process.env.VUE_APP_API_PANDORA_SERVICES}`
const BASE_URL_HELPDESK = `${process.env.VUE_APP_API_HELPDESK_SERVICES}`

// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL_ACCOUNT}/auth/sign-in`,
  LOGOUT: `${BASE_URL_ACCOUNT}/auth/sign-out`,
  VERIFICATION_LOGIN: `${BASE_URL_ACCOUNT}/auth/sign-in-verification`,
  FORGOT_PASSWORD: `${BASE_URL_ACCOUNT}/auth/forgot-password`,
  CHANGE_PASSWORD: `${BASE_URL_ACCOUNT}/user/change-password`,
  IDLE_SESSION: `${BASE_URL_ACCOUNT}/auth/idle`
}

export const API_E_VOUCHER = {
  LIST_E_VOUCHER: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/report`,
  OUTSTANDING_E_VOUCHER_REPORT: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/outstanding-voucher-report`,
  GLOSSARY_E_VOUCHER: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/glossary`,
  CLIENT_E_VOUCHER: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/client`,
  LIST_CLIENT_E_VOUCHER: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/list-client`,
  LIST_BULK_E_VOUCHER: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/list-bulk-voucher`,
  RELEASE_BULK_E_VOUCHER: `${BASE_URL_PROMOTION}/internal/e-superindo-voucher/release-bulk-voucher`,
  VERIFY_BULK_E_VOUCHER: `${BASE_URL_PROMOTION}/internal/e-superindo-voucher/validate-bulk-voucher`,
  ITEMS_E_VOUCHER: `${BASE_URL_PROMOTION}/internal/e-superindo-voucher/voucher-items`,
  RECAP_E_VOUCHER: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/recap-report`,
  DASHBOARD_E_VOUCHER: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/selling-report`,
  EXPORT_RECAP_E_VOUCHER_REPORT: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/export-recap-report`,
  EXPORT_E_VOUCHER_REPORT: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/export-voucher-report`,
  EXPORT_OUTSTANDING_REPORT: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/export-outstanding-voucher-report`,
  EXPORT_E_VOUCHER_REDEEM_REPORT: `${BASE_URL_PROMOTION}/dashboard/e-superindo-voucher/export-voucher-redeem-report`
}

export const API_CAMPAIGN = {
  LIST_CAMPAIGN: `${BASE_URL_LOYALITY}/campaign/list`,
  CREATE_CAMPAIGN: `${BASE_URL_LOYALITY}/campaign/add`,
  DETAIL_CAMPAIGN: id => `${BASE_URL_LOYALITY}/campaign/detail/${id}`,
  UPDATE_CAMPAIGN: id => `${BASE_URL_LOYALITY}/campaign/update/${id}`,
  DELETE_CAMPAIGN: id => `${BASE_URL_LOYALITY}/campaign/delete/${id}`,
  GET_PROMO_CODE: `${BASE_URL_CORE}/get-promo-info`,
  LIST_OFFER: `${BASE_URL_LOYALITY}/offer`,
  LIST_CONSUMER_OFFER: `${BASE_URL_LOYALITY}/offer/consumer-offer`,
  LIST_ALL_OFFER: `${BASE_URL_LOYALITY}/offer?is_all=true`,
  CREATE_OFFER: `${BASE_URL_LOYALITY}/offer`,
  DETAIL_OFFER: id => `${BASE_URL_LOYALITY}/offer/detail/${id}`,
  UPDATE_OFFER: id => `${BASE_URL_LOYALITY}/offer/${id}`,
  DELETE_OFFER: id => `${BASE_URL_LOYALITY}/offer/${id}`,
  LIST_BANNER: `${BASE_URL_LOYALITY}/banner`,
  DETAIL_BANNER: id => `${BASE_URL_LOYALITY}/banner/detail/${id}`,
  DELETE_BANNER: id => `${BASE_URL_LOYALITY}/banner/${id}`,
  CREATE_BANNER: `${BASE_URL_LOYALITY}/banner`,
  UPDATE_BANNER: id => `${BASE_URL_LOYALITY}/banner/${id}`,
  LIST_PUSH_MESSAGE: `${BASE_URL_LOYALITY}/push-message`,
  DETAIL_PUSH_MESSAGE: id => `${BASE_URL_LOYALITY}/push-message/detail/${id}`,
  DELETE_PUSH_MESSAGE: id => `${BASE_URL_LOYALITY}/push-message/${id}`,
  CREATE_PUSH_MESSAGE: `${BASE_URL_LOYALITY}/push-message`,
  UPDATE_PUSH_MESSAGE: id => `${BASE_URL_LOYALITY}/push-message/${id}`,
  LIST_ALL_TAG: `${BASE_URL_LOYALITY}/tag?is_all=true`,
  CALCULATE_TAG: `${BASE_URL_LOYALITY}/tag/calculate`,
  NOTIFICATION_OFFER: `${BASE_URL_ACCOUNT}/user-offer-notification`,
  LIST_ALL_NOTIF_OFFER: `${BASE_URL_ACCOUNT}/user-offer-notification?limit=999999999999999&page=1`,
  DELETE_NOTIFICATION_OFFER: id => `${BASE_URL_ACCOUNT}/user-offer-notification/${id}`,
  LIST_PRODUCT: `${BASE_URL_STORE}/product?is_all=true`,
  UPDATE_PUSH_MESSAGE_BACKGROUND_PROCESS: id => `${BASE_URL_LOYALITY}/loyalty-status/${id}?process=pushmessage`
}

export const API_DIGITAL_STAMP = {
  GET_PROMO_CODE: `${BASE_URL_CORE}/get-stamp-info`,
  GET_PROMO_CODE_REWARD: `${BASE_URL_CORE}/get-reward-info`,
  STAMP: `${BASE_URL_LOYALITY}/stamp`,
  STAMP_DETAIL: id => `${BASE_URL_LOYALITY}/stamp/detail/${id}`,
  STAMP_UPDATE_DELETE: id => `${BASE_URL_LOYALITY}/stamp/${id}`,
  BULK_UPLOAD_PRODUCT: `${BASE_URL_LOYALITY}/stamp/bulk-upload-excluded-product`,
  REWARD: `${BASE_URL_LOYALITY}/reward`,
  REWARD_DETAIL: id => `${BASE_URL_LOYALITY}/reward/detail/${id}`,
  REWARD_UPDATE_DELETE: id => `${BASE_URL_LOYALITY}/reward/${id}`,
  HOWTO_REDEEM: `${BASE_URL_LOYALITY}/how-to-redeem`,
  CONSUMER_STAMP_HISTORY: `${BASE_URL_LOYALITY}/stamp/consumer-stamp-history`,
  CONSUMER_COUPON_HISTORY: `${BASE_URL_LOYALITY}/reward/consumer-coupon-history`,
  CONSUMER_COUPON_HISTORY_DETAIL: id => `${BASE_URL_LOYALITY}/reward/consumer-coupon-history/detail/${id}`,
  EXPORT_CONSUMER_STAMP_HISTORY: `${BASE_URL_LOYALITY}/stamp/consumer-stamp-history/csv`,
  EXPORT_CONSUMER_COUPON_HISTORY: `${BASE_URL_LOYALITY}/reward/consumer-coupon-history/csv`,
  REPORT_COUPON: `${BASE_URL_LOYALITY}/report/coupon`,
  REPORT_COUPON_DETAIL: id => `${BASE_URL_LOYALITY}/report/coupon/detail/${id}`,
  EXPORT_REPORT_COUPON: `${BASE_URL_LOYALITY}/report/coupon/csv`,
  REPORT_PRODUCT: `${BASE_URL_LOYALITY}/report/product`,
  EXPORT_REPORT_PRODUCT: `${BASE_URL_LOYALITY}/report/product/csv`
}

export const API_POLLING_SURVEY = {
  FORM: `${BASE_URL_LOYALITY}/polling-and-survey/form`,
  FORM_DETAIL: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/detail/${id}`,
  FORM_UPDATE_DELETE: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/${id}`,
  FORM_DETAIL_RESPONSE: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/response/${id}`,
  EXPORT_REPORT_FORM_RESPONSE: id => `${BASE_URL_LOYALITY}/polling-and-survey/form/response/csv/${id}`,
  RESPONSE: `${BASE_URL_LOYALITY}/polling-and-survey/response`,
  RESPONSE_DETAIL: id => `${BASE_URL_LOYALITY}/polling-and-survey/response/detail/${id}`,
  EXPORT_RESPONSE: `${BASE_URL_LOYALITY}/polling-and-survey/response/csv`
}

export const API_CONSUMER = {
  LIST_CONSUMER: `${BASE_URL_ACCOUNT}/consumer`,
  DETAIL_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/detail/${id}`,
  UPDATE_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/update/${id}`,
  EXPORT_CONSUMER: `${BASE_URL_ACCOUNT}/consumer/export/csv`,
  EXISTING_PHONE_NUMBER: `${BASE_URL_ACCOUNT}/consumer/existing-phone-number`,
  LOCK_ACCOUNT_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/lock-consumer/${id}`,
  UNLOCK_ACCOUNT_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/unlock-consumer/${id}`,
  WITHDRAW_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/withdraw-consumer/${id}`,
  UNWITHDRAW_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/unwithdraw-consumer/${id}`,
  CONSUMER_ACTIVITY_LOG: id => `${BASE_URL_ACCOUNT}/consumer-log-history/${id}`,
  RESEND_EMAIL_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/verification/${id}`,
  POS_SALES_HISTORY: `${BASE_URL_LOYALITY}/transaction/pos-sales-history`,
  UNLINK_CARD: `${BASE_URL_ACCOUNT}/consumer/unlink-card`,
  LINK_CARD: `${BASE_URL_ACCOUNT}/consumer/link-card`,
  LIST_CATEGORY_FAQ: `${BASE_URL_LOYALITY}/cms-faq-category/options`,
  LIST_CATEGORY_HC: `${BASE_URL_ACCOUNT}/consumer-help-center/category`,
  LIST_TYPE_HC: `${BASE_URL_ACCOUNT}/consumer-help-center/type`,
  LIST_FAQ: `${BASE_URL_LOYALITY}/cms-faq`,
  LIST_HELP_CENTER: `${BASE_URL_ACCOUNT}/consumer-help-center`,
  EXPORT_HELP_CENTER: `${BASE_URL_ACCOUNT}/consumer-help-center/export/csv`,
  DETAIL_HELP_CENTER:  id => `${BASE_URL_ACCOUNT}/consumer-help-center/${id}`,
  SOLVED_HELP_CENTER: id => `${BASE_URL_ACCOUNT}/consumer-help-center/${id}`,
  SOLVED_HELP_CENTER_BULK: `${BASE_URL_ACCOUNT}/consumer-help-center/bulk`,
  LIST_APPROVAL_MESSAGE: `${BASE_URL_ACCOUNT}/approval-user`,
  LIST_APPROVAL_MESSAGE_ALL: `${BASE_URL_ACCOUNT}/approval-user?limit=999999999999999&page=1`,
  DELETE_APPROVAL: id => `${BASE_URL_ACCOUNT}/approval-user/${id}`,
  CREATE_APPROVAL: `${BASE_URL_ACCOUNT}/approval-user`,
  CREATE_FAQ: `${BASE_URL_LOYALITY}/cms-faq`,
  DETAIL_FAQ: id => `${BASE_URL_LOYALITY}/cms-faq/${id}`,
  UPDATE_FAQ: id => `${BASE_URL_LOYALITY}/cms-faq/${id}`,
  DELETE_FAQ: id => `${BASE_URL_LOYALITY}/cms-faq/${id}`,
  LIST_GENDER: `${BASE_URL_LOYALITY}/gender`,
  LIST_REGION: `${BASE_URL_LOYALITY}/region`,
  LIST_CONSUMER_TAG: `${BASE_URL_LOYALITY}/tag/consumer-tag`,
  LIST_ADDRESS: id => `${BASE_URL_ACCOUNT}/consumer-address/list/${id}`,
  LIST_PROVINCE: `${BASE_URL_ACCOUNT}/region/province`,
  LIST_CITY: `${BASE_URL_ACCOUNT}/region/city`,
  DETAIL_ADDRESS: id => `${BASE_URL_ACCOUNT}/consumer-address/detail/${id}`,
  UPDATE_ADDRESS: id => `${BASE_URL_ACCOUNT}/consumer-address/update/${id}`,
  SEND_CREATE_PASSWORD_CONSUMER: id => `${BASE_URL_ACCOUNT}/consumer/send-create-password/${id}`,
  TEMPLATE_HELP_CENTER: `${BASE_URL_ACCOUNT}/template-help-center`,
  TEMPLATE_HELP_CENTER_BY_ID: id => `${BASE_URL_ACCOUNT}/template-help-center/${id}`,
  UPDATE_SOLVE_BY_EMAIL: id => `${BASE_URL_ACCOUNT}/consumer-help-center/share/${id}`,
  RATING: `${BASE_URL_LOYALITY}/rating`,
  RATING_BY_ID: id => `${BASE_URL_LOYALITY}/rating/detail/${id}`,
  EXPORT_RATING: `${BASE_URL_LOYALITY}/rating/csv`,
  LOGIN_ATTEMP: `${BASE_URL_ACCOUNT}/consumer-log-login/report`,
  LOGIN_ATTEMP_DETAIL: `${BASE_URL_ACCOUNT}/consumer-log-login`,
  LOGIN_ATTEMP_CSV: `${BASE_URL_ACCOUNT}/consumer-log-login/report/csv`,
  LOGIN_ATTEMP_CSV_DETAIL: `${BASE_URL_ACCOUNT}/consumer-log-login/csv`,
  BULK_UPLOAD_CONSUMER_HC: `${BASE_URL_ACCOUNT}/consumer-help-center/bulk-upload-consumer-help-center`
}

export const API_MASTER_DATA = {
  LIST_CONSUMER_BY_DETAIL_CUSTOM_TAG: `${BASE_URL_ACCOUNT}/consumer/list-consumer`,
  CONSUMER_TAG: `${BASE_URL_LOYALITY}/tag`,
  BULK_UPLOAD_CONSUMER_TAG: `${BASE_URL_ACCOUNT}/consumer/bulk-upload-consumer-tag`,
  UPDATE_CUSTOM_CONSUMER_TAG: id => `${BASE_URL_LOYALITY}/tag/update/${id}`,
  DETAIL_CUSTOM_CONSUMER_TAG: id => `${BASE_URL_LOYALITY}/tag/detail/${id}`,
  DELETE_CUSTOM_CONSUMER_TAG: id => `${BASE_URL_LOYALITY}/tag/delete/${id}`,
  LIST_ALL_GROUP_STORE: `${BASE_URL_LOYALITY}/group-store?is_all=true`,
  LIST_GROUP_STORE: `${BASE_URL_LOYALITY}/group-store`,
  CREATE_GROUP_STORE: `${BASE_URL_LOYALITY}/group-store/create`,
  DETAIL_GROUP_STORE: id => `${BASE_URL_LOYALITY}/group-store/detail/${id}`,
  UPDATE_GROUP_STORE: id => `${BASE_URL_LOYALITY}/group-store/update/${id}`,
  DELETE_GROUP_STORE: id => `${BASE_URL_LOYALITY}/group-store/delete/${id}`,
  LIST_STORE_FROM_GROUP_STORE: data => `${BASE_URL_LOYALITY}/group-store/list-store?${data}`,
  LIST_ALL_STORE: `${BASE_URL_LOYALITY}/store?is_all=true`,
  SYNC_STORE: `${BASE_URL_CORE}/sync-store`,
  LIST_STORE: `${BASE_URL_LOYALITY}/store`,
  LIST_PRODUCT: `${BASE_URL_LOYALITY}/product`,
  LIST_PRODUCT_CATEGORY: `${BASE_URL_LOYALITY}/product-category?is_all=true`,
  PRIVACY_POLICY: `${BASE_URL_LOYALITY}/privacy-policy`,
  SETTING_APP: id => `${BASE_URL_ACCOUNT}/config/mobile/${id}`,
  SETTING_APP_ANDROID: `${BASE_URL_ACCOUNT}/config/mobile/android`,
  SETTING_APP_IOS: `${BASE_URL_ACCOUNT}/config/mobile/ios`,
  TERM_CONDITION: `${BASE_URL_LOYALITY}/terms-and-condition`
}

export const API_USER_MANAGEMENT = {
  LIST_ROLE: `${BASE_URL_ACCOUNT}/role/list`,
  CREATE_ROLE: `${BASE_URL_ACCOUNT}/role/add`,
  DETAIL_ROLE: id => `${BASE_URL_ACCOUNT}/role/detail/${id}`,
  DELETE_ROLE: id => `${BASE_URL_ACCOUNT}/role/delete/${id}`,
  UPDATE_ROLE: id => `${BASE_URL_ACCOUNT}/role/update/${id}`,
  LIST_USER: `${BASE_URL_ACCOUNT}/user/list`,
  LIST_USER_ALL: `${BASE_URL_ACCOUNT}/user/options`,
  CREATE_USER: `${BASE_URL_ACCOUNT}/user`,
  ALL_DETAIL_USER: `${BASE_URL_ACCOUNT}/user/detail`,
  DETAIL_USER: id => `${BASE_URL_ACCOUNT}/user/detail/${id}`,
  UPDATE_USER: id => `${BASE_URL_ACCOUNT}/user/update/${id}`,
  DELETE_USER: id => `${BASE_URL_ACCOUNT}/user/delete/${id}`,
  RESET_PASSWORD: `${BASE_URL_ACCOUNT}/user/reset-password`,
  RESET_PASSWORD_USER_BY_ID: id => `${BASE_URL_ACCOUNT}/user/reset-password/${id}`,
  DETAIL_PROFILE: `${BASE_URL_ACCOUNT}/user/profile`,
  USER_LOG_HISTORY: `${BASE_URL_ACCOUNT}/user-log-history`,
  ALL_USERNAME: `${BASE_URL_ACCOUNT}/user/username`,
  LIST_PERMISSIONS: `${BASE_URL_ACCOUNT}/permission/list`,
  UPDATE_ROLE_PERMISSIONS: `${BASE_URL_ACCOUNT}/role-permission/update`,
  LIST_ROLE_PERMISSIONS: `${BASE_URL_ACCOUNT}/role-permission/list`
}

export const API_MASTER_ALP = {
  LIST_MEMBER_ALP: `${BASE_URL_ACCOUNT}/alp/pagination`,
  DETAIL_MEMBER_ALP: employeeNo => `${BASE_URL_ACCOUNT}/alp/detail/${employeeNo}`,
  HISTORY_MEMBER_ALP: associateId => `${BASE_URL_ACCOUNT}/alp/associate/history/${associateId}`,
  UPDATE_MEMBER_ALP: `${BASE_URL_ACCOUNT}/alp/update`,
  UPLOAD_BULK_MEMBER_ALP: `${BASE_URL_ACCOUNT}/alp/upload/preview`,
  CONFIRM_BULK_MEMBER_ALP: `${BASE_URL_ACCOUNT}/alp/upload`
}

export const API_STORE_MANAGEMENT = {
  SYNC_STORE: `${BASE_URL_CORE}/sync-store`,
  SYNC_STORE_PRODUCT: `${BASE_URL_CORESTORE}/legacy/sync/store-product`,
  STATUS_SYNC_STORE_PRODUCT: `${BASE_URL_ORDER}/import-status/sync-store-product`,
  BULK_STORE:  `${BASE_URL_STORE}/store/bulk-update`,
  PREVIEW_BULK_STORE: `${BASE_URL_STORE}/store/preview-bulk-update`,
  LIST_STORE: `${BASE_URL_STORE}/store`,
  DETAIL_STORE: id => `${BASE_URL_STORE}/store/detail/${id}`,
  UPDATE_STORE: id => `${BASE_URL_STORE}/store/update/${id}`,
  LIST_STORE_PRODUCT: id => `${BASE_URL_STORE}/store-product/list/${id}`,
  DETAIL_STORE_PRODUCT: id => `${BASE_URL_STORE}/store-product/detail/${id}`,
  UPDATE_STORE_PRODUCT: id => `${BASE_URL_STORE}/store-product/update/${id}`,
  LIST_PROVINCE: `${BASE_URL_ACCOUNT}/region/province`,
  LIST_CITY: `${BASE_URL_ACCOUNT}/region/city`,
  LIST_DISTRICT: `${BASE_URL_ACCOUNT}/region/district`,
  LIST_VILLAGE: `${BASE_URL_ACCOUNT}/region/village`,
  LIST_DELIVERY_METHOD: `${BASE_URL_ORDER}/delivery-method/list-active`,
  LIST_REGION: `${BASE_URL_STORE}/store-regions`,
  ADD_REGION: `${BASE_URL_STORE}/store-regions/add`,
  DELETE_REGION: id => `${BASE_URL_STORE}/store-regions/${id}`,
  UPDATE_REGION: id => `${BASE_URL_STORE}/store-regions/${id}`
}

export const API_CATEGORY = {
  LIST_SUB_CATEGORY: `${BASE_URL_STORE}/sub-category/list`,
  DETAIL_SUB_CATEGORY: id => `${BASE_URL_STORE}/sub-category/detail/${id}`,
  UPDATE_SUB_CATEGORY: id => `${BASE_URL_STORE}/sub-category/update/${id}`,
  LIST_PARENT_CATEGORY: `${BASE_URL_STORE}/parent-category/list`,
  DETAIL_PARENT_CATEGORY: id => `${BASE_URL_STORE}/parent-category/detail/${id}`,
  CREATE_PARENT_CATEGORY: `${BASE_URL_STORE}/parent-category/add`,
  UPDATE_PARENT_CATEGORY: id => `${BASE_URL_STORE}/parent-category/update/${id}`,
  POSITION_PARENT_CATEGORY: `${BASE_URL_STORE}/parent-category/update-position`
}

export const API_PROMO_MANAGEMENT = {
  LIST_PROMO_MANAGEMENT: `${BASE_URL_ORDER}/voucher/list`,
  CREATE_PROMO_MANAGEMENT: `${BASE_URL_ORDER}/voucher/add`,
  DETAIL_PROMO_MANAGEMENT: id => `${BASE_URL_ORDER}/voucher/detail/${id}`,
  UPDATE_PROMO_MANAGEMENT: id => `${BASE_URL_ORDER}/voucher/update/${id}`,
  UPLOAD_BULK_PROMO_MANAGEMENT: `${BASE_URL_ORDER}/voucher/bulk-upload`,
  CONFIRM_BULK_PROMO_MANAGEMENT: `${BASE_URL_ORDER}/voucher/bulk-add`,
  STATUS_BULK_PROMO_MANAGEMENT: `${BASE_URL_ORDER}/import-status/bulk-add-voucher`
}

export const API_3PL_SETTING = {
  LIST_3PL_SETTING: `${BASE_URL_ORDER}/delivery-method`,
  DETAIL_3PL_SETTING: id => `${BASE_URL_ORDER}/delivery-method/${id}`,
  UPDATE_3PL_SETTING: `${BASE_URL_ORDER}/delivery-method`
}

export const API_PLANOGRAM_MANAGEMENT = {
  LIST_PLANOGRAM_MANAGEMENT: `${BASE_URL_STORE}/planogram/list`,
  POSITION_PLANOGRAM_MANAGEMENT: `${BASE_URL_STORE}/planogram/update-position`,
  CREATE_PLANOGRAM_MANAGEMENT: `${BASE_URL_STORE}/planogram/add`,
  SYNC_PRODUCT_PLANOGRAM_MANAGEMENT: `${BASE_URL_STORE}/planogram/sync-product`,
  SYNC_CATEGORY_PLANOGRAM_MANAGEMENT: `${BASE_URL_STORE}/planogram/sync-category`,
  DETAIL_PLANOGRAM_MANAGEMENT: id => `${BASE_URL_STORE}/planogram/detail/${id}`,
  UPDATE_PLANOGRAM_MANAGEMENT: id => `${BASE_URL_STORE}/planogram/update/${id}`
}

// Employee
export const API_MOBILE = {
  RESET_PASSWORD: `${BASE_URL_ACCOUNT_MOBILE}/auth/change-password`,
  EMAIL_VERIFICATION: `${BASE_URL_ACCOUNT_MOBILE}/auth/verification`
}
export const API_USER = {
  UPDATE_USER_PROFILE:  `${BASE_URL_ACCOUNT}/user/profile`,
  DETAIL_USER: (id) => `${BASE_URL_LOYALITY}/user/${id}`
}

//orion
export const API_ORION_PRODUCT = {
  LIST_PRODUCT: `${BASE_URL_STORE}/product`,
  DETAIL_PRODUCT: (id) => `${BASE_URL_STORE}/product/${id}`,
  UPDATE_PRODUCT: (id) => `${BASE_URL_STORE}/product/${id}`,
  LIST_STORE: (params) => {
    // Set default values for limit and page
    params.limit = params.limit || 10
    params.page = params.page || 1

    const url = new URL(`${BASE_URL_STORE}/product/view-store/${params.id}`)

    // Omit 'id' from the query parameters
    delete params.id

    // Append the remaining query parameters from the 'params' object
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value)
    }

    return url.toString()
  },
  PREVIEW_BULK_EDIT: `${BASE_URL_STORE}/product/preview-bulk-update`,
  SUBMIT_BULK_EDIT: `${BASE_URL_STORE}/product/bulk-update`,
  SYNC_PRODUCT: `${BASE_URL_CORESTORE}/legacy/sync/product`,
  TEMPLATE_PRODUCT: `${BASE_URL_STORE_PUBLIC}/public/uploads/products/template-product-management.xlsx`,
  STATUS_SYNC_PRODUCT: `${BASE_URL_ORDER}/import-status/sync-product`,
  STATUS_BULK: `${BASE_URL_ORDER}/import-status/bulk-update-product`,
  EXPORT_PRODUCT: `${BASE_URL_STORE}/product/csv`
}

export const API_MAX_PURCHASE = {
  LIST_PRODUCT: `${BASE_URL_STORE}/product?is_all=true`,
  LIST_MAX_PURCHASE: `${BASE_URL_STORE}/product-restriction/list`,
  DETAIL_MAX_PURCHASE: (id) => `${BASE_URL_STORE}/product-restriction/detail/${id}`,
  CREATE_MAX_PURCHASE: `${BASE_URL_STORE}/product-restriction/add`,
  UPDATE_MAX_PURCHASE: (id) => `${BASE_URL_STORE}/product-restriction/update/${id}`,
  DELETE_MAX_PURCHASE: (id) => `${BASE_URL_STORE}/product-restriction/delete/${id}`
}

export const API_STORE = {
  LIST_STORE: `${BASE_URL_STORE}/store`
}

export const API_SETTING = {
  GENERAL_SETTING: `${BASE_URL_ACCOUNT}/general-setting`,
  UPDATE_GENERAL_SETTING: `${BASE_URL_ACCOUNT}/general-setting/update`,
  APP_VERSION: `${BASE_URL_ACCOUNT}/config/mobile/all`
}

export const API_ORDER_MANAGEMENT = {
  LIST_ORDER: `${BASE_URL_ORDER}/order/list`,
  LIST_ORDER_ITEM: (id) => `${BASE_URL_ORDER}/order/purchased-items/${id}`,
  DETAIL_ORDER: (id) => `${BASE_URL_ORDER}/order/detail/${id}`,
  PREVIEW_ORDER: (id) => `${BASE_URL_ORDER}/order/print-detail/${id}`,
  GET_NEW_DRIVER: (id) => `${BASE_URL_ORDER}/order/get-new-driver/${id}`,
  UPDATE_UNPICK_ALL: (id) => `${BASE_URL_ORDER}/order/cancel/${id}`,
  GET_LIST_CANCEL_REASON: `${BASE_URL_ORDER}/order/cancel-reason`,
  UPDATE_ORDER_ITEMS: (id) => `${BASE_URL_ORDER}/order/cancel-reason-items/${id}`
}

export const API_SALES_DASHBOARD = {
  LIST_SUPERVISOR: `${BASE_URL_ACCOUNT}/user/list-supervisor`,
  SALES_MEMBER: `${BASE_URL_ANALYTIC}/sales-member`,
  EXPORT_SALES_MEMBER: (param) => `${BASE_URL_ANALYTIC}/sales-member/${param}`,
  MEMBER_TRANSACTION: `${BASE_URL_ANALYTIC}/member-transaction`,
  EXPORT_MEMBER_TRANSACTION: (param) => `${BASE_URL_ANALYTIC}/member-transaction/${param}`,
  APC_MEMBER: `${BASE_URL_ANALYTIC}/apc-member`,
  EXPORT_APC_MEMBER: (param) => `${BASE_URL_ANALYTIC}/apc-member/${param}`,
  AVERAGE_TRANSACTION: `${BASE_URL_ANALYTIC}/avg-transaction`,
  EXPORT_AVERAGE_TRANSACTION: (param) => `${BASE_URL_ANALYTIC}/avg-transaction/${param}`,
  SUMMARY_SALES_REPORT: `${BASE_URL_ANALYTIC}/summary-sales-report`,
  EXPORT_SUMMARY_SALES_REPORT: (param) => `${BASE_URL_ANALYTIC}/summary-sales-report/${param}`
}

export const API_PANDORA_CUSTOMER_ANALYTICS = {
  CUSTOMER_ANALYTICS: `${BASE_URL_ANALYTIC}/customer`,
  EXPORT_CUSTOMER_ANALYTICS: (param) => `${BASE_URL_ANALYTIC}/customer/${param}`
}

export const API_SALES_ANALYTICS = {
  TOP_SALES_CATEGORY: `${BASE_URL_ANALYTIC}/top-sales-category`,
  EXPORT_TOP_SALES_CATEGORY: (param) => `${BASE_URL_ANALYTIC}/top-sales-category/${param}`,
  TOP_SALES_PLU: `${BASE_URL_ANALYTIC}/top-sales-plu`,
  EXPORT_TOP_SALES_PLU: (param) => `${BASE_URL_ANALYTIC}/top-sales-plu/${param}`
}

export const API_PANDORA_DASHBOARD_ANALYTIC = {
  // OverView
  OVERVIEW_TOTAL: `${BASE_URL_ANALYTIC2}/overview/count-all`,
  OVERVIEW_OPEN: `${BASE_URL_ANALYTIC2}/overview/consumer-open`,
  OVERVIEW_REGISTRATION: `${BASE_URL_ANALYTIC2}/overview/consumer-register`,
  OVERVIEW_ACTIVE: `${BASE_URL_ANALYTIC2}/overview/consumer-active`,
  OVERVIEW_DOWNLOAD: `${BASE_URL_ANALYTIC2}/overview/consumer-download`,
  OVERVIEW_VISITING: `${BASE_URL_ANALYTIC2}/overview/consumer-visit`,
  OVERVIEW_ENGAGED: `${BASE_URL_ANALYTIC2}/overview/consumer-engage`,
  OVERVIEW_TRANSACTION: `${BASE_URL_ANALYTIC2}/overview/consumer-transaction`,
  OVERVIEW_AVG_ACTIVE: `${BASE_URL_ANALYTIC2}/overview/average-consumer-active`,
  OVERVIEW_AVG_VISITING: `${BASE_URL_ANALYTIC2}/overview/average-consumer-visit`,
  OVERVIEW_AVG_ENGAGED: `${BASE_URL_ANALYTIC2}/overview/average-consumer-engage`,
  OVERVIEW_EXPORT_COUNTER: param => `${BASE_URL_ANALYTIC2}/overview/count-all/${param}`,
  OVERVIEW_EXPORT_OPEN: param => `${BASE_URL_ANALYTIC2}/overview/consumer-open/${param}`,
  OVERVIEW_EXPORT_REGISTRATION: param => `${BASE_URL_ANALYTIC2}/overview/consumer-register/${param}`,
  OVERVIEW_EXPORT_ACTIVE: param => `${BASE_URL_ANALYTIC2}/overview/consumer-active/${param}`,
  OVERVIEW_EXPORT_VISITING: param => `${BASE_URL_ANALYTIC2}/overview/consumer-visit/${param}`,
  OVERVIEW_EXPORT_DOWNLOAD: param => `${BASE_URL_ANALYTIC2}/overview/consumer-download/${param}`,
  OVERVIEW_EXPORT_ENGAGED: param => `${BASE_URL_ANALYTIC2}/overview/consumer-engage/${param}`,
  OVERVIEW_EXPORT_TRANSACTION: param => `${BASE_URL_ANALYTIC2}/overview/consumer-transaction/${param}`,
  OVERVIEW_EXPORT_AVG_ACTIVE: param => `${BASE_URL_ANALYTIC2}/overview/average-consumer-active/${param}`,
  OVERVIEW_EXPORT_AVG_VISITING: param => `${BASE_URL_ANALYTIC2}/overview/average-consumer-visit/${param}`,
  OVERVIEW_EXPORT_AVG_ENGAGED: param => `${BASE_URL_ANALYTIC2}/overview/average-consumer-engage/${param}`,
  SHARE_COUNTER_OVERVIEW: `${BASE_URL_ANALYTIC2}/overview/count-all/share`,
  SHARE_OVERVIEW_OPEN: `${BASE_URL_ANALYTIC2}/overview/consumer-open/share`,
  SHARE_OVERVIEW_REGISTRATION: `${BASE_URL_ANALYTIC2}/overview/consumer-register/share`,
  SHARE_OVERVIEW_ACTIVE: `${BASE_URL_ANALYTIC2}/overview/consumer-active/share`,
  SHARE_OVERVIEW_VISITING: `${BASE_URL_ANALYTIC2}/overview/consumer-visit/share`,
  SHARE_OVERVIEW_DOWNLOAD: `${BASE_URL_ANALYTIC2}/overview/consumer-download/share`,
  SHARE_OVERVIEW_ENGAGED: `${BASE_URL_ANALYTIC2}/overview/consumer-engage/share`,
  SHARE_OVERVIEW_UNIQUE: `${BASE_URL_ANALYTIC2}/overview/consumer-transaction/share`,
  SHARE_OVERVIEW_AVG_ACTIVE: `${BASE_URL_ANALYTIC2}/overview/average-consumer-active/share`,
  SHARE_OVERVIEW_AVG_VISITING: `${BASE_URL_ANALYTIC2}/overview/average-consumer-visit/share`,
  SHARE_OVERVIEW_AVG_ENGAGED: `${BASE_URL_ANALYTIC2}/overview/average-consumer-engage/share`,
  // New Overview
  MONTHLY_NEW_REGISTRATION: `${BASE_URL_ANALYTIC2}/new-overview/monthly/new-registration`,
  MONTHLY_NEW_OPEN: `${BASE_URL_ANALYTIC2}/new-overview/monthly/new-open`,
  MONTHLY_ACTIVE_MEMBER: `${BASE_URL_ANALYTIC2}/new-overview/monthly/active-member`,
  MONTHLY_ENGAGED_MEMBER: `${BASE_URL_ANALYTIC2}/new-overview/monthly/engage-member`,
  WEEKLY_NEW_REGISTRATION: `${BASE_URL_ANALYTIC2}/new-overview/weekly/new-registration`,
  WEEKLY_NEW_OPEN: `${BASE_URL_ANALYTIC2}/new-overview/weekly/new-open`,
  WEEKLY_ACTIVE_MEMBER: `${BASE_URL_ANALYTIC2}/new-overview/weekly/active-member`,
  WEEKLY_ENGAGED_MEMBER: `${BASE_URL_ANALYTIC2}/new-overview/weekly/engage-member`,
  // Customer
  CUSTOMER_COUNT: `${BASE_URL_ANALYTIC2}/consumer/count-all`,
  CUSTOMER_COUNT_SUMMARY: `${BASE_URL_ANALYTIC2}/new-consumer/count-summary`,
  CUSTOMER_COUNT_NEW_ONLINE: `${BASE_URL_ANALYTIC2}/new-consumer/new/count-online`,
  CUSTOMER_COUNT_EXIST_ONLINE: `${BASE_URL_ANALYTIC2}/new-consumer/exist/count-online`,
  CUSTOMER_NEW_USER_ONLINE: `${BASE_URL_ANALYTIC2}/new-consumer/new/graph-online`,
  CUSTOMER_EXIST_USER_ONLINE: `${BASE_URL_ANALYTIC2}/new-consumer/exist/graph-online`,
  CUSTOMER_COUNT_NEW_OFFLINE: `${BASE_URL_ANALYTIC2}/new-consumer/new/count-offline`,
  CUSTOMER_COUNT_EXIST_OFFLINE: `${BASE_URL_ANALYTIC2}/new-consumer/exist/count-offline`,
  CUSTOMER_NEW_USER_OFFLINE: `${BASE_URL_ANALYTIC2}/new-consumer/new/graph-offline`,
  CUSTOMER_EXIST_USER_OFFLINE: `${BASE_URL_ANALYTIC2}/new-consumer/exist/graph-offline`,
  CUSTOMER_TYPE: `${BASE_URL_ANALYTIC2}/new-consumer/device-type`,
  CUSTOMER_TREND: `${BASE_URL_ANALYTIC2}/new-consumer/device-type-trend`,
  CUSTOMER_GENDER: `${BASE_URL_ANALYTIC2}/new-consumer/gender-trend`,
  CUSTOMER_DEMOGRAPHIC: `${BASE_URL_ANALYTIC2}/new-consumer/demographic`,
  CUSTOMER_DNR: `${BASE_URL_ANALYTIC2}/consumer/compare-dnr`,
  CUSTOMER_AVE: `${BASE_URL_ANALYTIC2}/consumer/compare-ave`,
  EXPORT_CUSTOMER_COUNT: param => `${BASE_URL_ANALYTIC2}/consumer/count-all/${param}`,
  EXPORT_CUSTOMER_COUNT_SUMMARY: param => `${BASE_URL_ANALYTIC2}/new-consumer/count-summary/${param}`,
  EXPORT_CUSTOMER_NEW_USER_ONLINE: param => `${BASE_URL_ANALYTIC2}/new-consumer/new/graph-online/${param}`,
  EXPORT_CUSTOMER_EXIST_USER_ONLINE: param => `${BASE_URL_ANALYTIC2}/new-consumer/exist/graph-online/${param}`,
  EXPORT_CUSTOMER_NEW_USER_OFFLINE: param => `${BASE_URL_ANALYTIC2}/new-consumer/new/graph-offline/${param}`,
  EXPORT_CUSTOMER_EXIST_USER_OFFLINE: param => `${BASE_URL_ANALYTIC2}/new-consumer/exist/graph-offline/${param}`,
  EXPORT_CUSTOMER_TYPE: param => `${BASE_URL_ANALYTIC2}/new-consumer/device-type/${param}`,
  EXPORT_CUSTOMER_TREND: param => `${BASE_URL_ANALYTIC2}/new-consumer/device-type-trend/${param}`,
  EXPORT_CUSTOMER_GENDER: param => `${BASE_URL_ANALYTIC2}/new-consumer/gender-trend/${param}`,
  EXPORT_CUSTOMER_DEMOGRAPHIC: param => `${BASE_URL_ANALYTIC2}/new-consumer/demographic/${param}`,
  EXPORT_CUSTOMER_DNR: param => `${BASE_URL_ANALYTIC2}/consumer/compare-dnr/${param}`,
  EXPORT_CUSTOMER_AVE: param => `${BASE_URL_ANALYTIC2}/consumer/compare-ave/${param}`,
  SHARE_COUNTER_CUSTOMER: `${BASE_URL_ANALYTIC2}/consumer/count-all/share`,
  SHARE_CUSTOMER_COUNT_SUMMARY: `${BASE_URL_ANALYTIC2}/new-consumer/count-summary/share`,
  SHARE_CUSTOMER_NEW_USER_ONLINE: `${BASE_URL_ANALYTIC2}/new-consumer/new/graph-online/share`,
  SHARE_CUSTOMER_EXIST_USER_ONLINE: `${BASE_URL_ANALYTIC2}/new-consumer/exist/graph-online/share`,
  SHARE_CUSTOMER_NEW_USER_OFFLINE: `${BASE_URL_ANALYTIC2}/new-consumer/new/graph-offline/share`,
  SHARE_CUSTOMER_EXIST_USER_OFFLINE: `${BASE_URL_ANALYTIC2}/new-consumer/exist/graph-offline/share`,
  SHARE_CUSTOMER_TYPE: `${BASE_URL_ANALYTIC2}/new-consumer/device-type/share`,
  SHARE_CUSTOMER_TREND: `${BASE_URL_ANALYTIC2}/new-consumer/device-type-trend/share`,
  SHARE_CUSTOMER_GENDER: `${BASE_URL_ANALYTIC2}/new-consumer/gender-trend/share`,
  SHARE_CUSTOMER_DEMOGRAPHIC: `${BASE_URL_ANALYTIC2}/new-consumer/demographic/share`,
  SHARE_CUSTOMER_DNR: `${BASE_URL_ANALYTIC2}/consumer/compare-dnr/share`,
  SHARE_CUSTOMER_AVE: `${BASE_URL_ANALYTIC2}/consumer/compare-ave/share`,
  // Marketing
  LIST_CAMPAIGN: `${BASE_URL_LOYALITY}/campaign/list?is_all=true`,
  LIST_OFFER: `${BASE_URL_LOYALITY}/offer?is_all=true&is_show_delete=true`,
  LIST_OFFER_LIMIT: `${BASE_URL_LOYALITY}/offer`,
  LIST_BANNER: `${BASE_URL_LOYALITY}/banner?is_all=true&is_show_delete=true`,
  LIST_MESSAGE: `${BASE_URL_LOYALITY}/push-message?is_all=true&is_show_delete=true`,
  LIST_STORE: `${BASE_URL_LOYALITY}/store?is_all=true`,
  MARKETING_COUNTER: `${BASE_URL_ANALYTIC2}/marketing/count-all`,
  MARKETING_TOP_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/top-redeem`,
  MARKETING_TOP_CLICK: `${BASE_URL_ANALYTIC2}/content-banner/top-click`,
  MARKETING_UNIQUE_TRANSACTION: `${BASE_URL_ANALYTIC2}/marketing/unique-transaction`,
  MARKETING_SALES: `${BASE_URL_ANALYTIC2}/marketing/sales`,
  MARKETING_AVG_TRANSACTION: `${BASE_URL_ANALYTIC2}/marketing/avg-transaction`,
  EXPORT_MARKETING_UNIQUE_TRANSACTION: param => `${BASE_URL_ANALYTIC2}/marketing/unique-transaction/${param}`,
  EXPORT_MARKETING_SALES: param => `${BASE_URL_ANALYTIC2}/marketing/sales/${param}`,
  EXPORT_MARKETING_AVG_TRANSACTION: param => `${BASE_URL_ANALYTIC2}/marketing/avg-transaction/${param}`,
  EXPORT_MARKETING_TOP_BANNER: param => `${BASE_URL_ANALYTIC2}/content-banner/top-click/${param}`,
  EXPORT_MARKETING_TOP_OFFER: param => `${BASE_URL_ANALYTIC2}/content-offer/top-redeem/${param}`,
  EXPORT_MARKETING_COUNTER: param => `${BASE_URL_ANALYTIC2}/marketing/count-all/${param}`,
  SHARE_MARKETING_UNIQUE_TRANSACTION: `${BASE_URL_ANALYTIC2}/marketing/unique-transaction/share`,
  SHARE_MARKETING_SALES: `${BASE_URL_ANALYTIC2}/marketing/sales/share`,
  SHARE_MARKETING_TOP_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/top-redeem/share`,
  SHARE_MARKETING_TOP_CLICK: `${BASE_URL_ANALYTIC2}/content-banner/top-click/share`,
  SHARE_MARKETING_AVERAGE: `${BASE_URL_ANALYTIC2}/marketing/avg-transaction/share`,
  SHARE_MARKETING_COUNTER: `${BASE_URL_ANALYTIC2}/marketing/count-all/share`,
  // Content Banner
  COUNTER_BANNER: `${BASE_URL_ANALYTIC2}/new-content-banner/banner-summary`,
  COUNTER_BANNER_IMPRESS_CLICK: `${BASE_URL_ANALYTIC2}/new-content-banner/banner-impression-click`,
  BANNER_TOP_IMPRESS: `${BASE_URL_ANALYTIC2}/new-content-banner/top-rank-impression`,
  BANNER_TOP_CLICK: `${BASE_URL_ANALYTIC2}/new-content-banner/top-rank-click`,
  BANNER_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC2}/new-content-banner/bottom-rank-impression`,
  BANNER_BOTTOM_CLICK: `${BASE_URL_ANALYTIC2}/new-content-banner/bottom-rank-click`,
  EXPORT_COUNTER_BANNER: param => `${BASE_URL_ANALYTIC2}/new-content-banner/banner-summary/${param}`,
  EXPORT_BANNER_IMPRESS_CLICK: param => `${BASE_URL_ANALYTIC2}/new-content-banner/banner-impression-click/${param}`,
  EXPORT_BANNER_TOP_IMPRESS: param => `${BASE_URL_ANALYTIC2}/new-content-banner/top-rank-impression/${param}`,
  EXPORT_BANNER_TOP_CLICK: param => `${BASE_URL_ANALYTIC2}/new-content-banner/top-rank-click/${param}`,
  EXPORT_BANNER_BOTTOM_IMPRESS: param => `${BASE_URL_ANALYTIC2}/new-content-banner/bottom-rank-impression/${param}`,
  EXPORT_BANNER_BOTTOM_CLICK: param => `${BASE_URL_ANALYTIC2}/new-content-banner/bottom-rank-click/${param}`,
  SHARE_COUNTER_BANNER: `${BASE_URL_ANALYTIC2}/new-content-banner/banner-summary/share`,
  SHARE_BANNER_IMPRESS_CLICK: `${BASE_URL_ANALYTIC2}/new-content-banner/banner-impression-click/share`,
  SHARE_BANNER_TOP_IMPRESS: `${BASE_URL_ANALYTIC2}/new-content-banner/top-rank-impression/share`,
  SHARE_BANNER_TOP_CLICK: `${BASE_URL_ANALYTIC2}/new-content-banner/top-rank-click/share`,
  SHARE_BANNER_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC2}/new-content-banner/bottom-rank-impression/share`,
  SHARE_BANNER_BOTTOM_CLICK: `${BASE_URL_ANALYTIC2}/new-content-banner/bottom-rank-click/share`,
  // Content Message
  COUNTER_MESSAGE: `${BASE_URL_ANALYTIC2}/content-pushmessage/total`,
  COUNTER_MESSAGE_IMPRESS_CLICK: `${BASE_URL_ANALYTIC2}/content-pushmessage/sent-impress-click`,
  MESSAGE_TOP_IMPRESS: `${BASE_URL_ANALYTIC2}/content-pushmessage/top-impress`,
  MESSAGE_TOP_CLICK: `${BASE_URL_ANALYTIC2}/content-pushmessage/top-click`,
  MESSAGE_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC2}/content-pushmessage/bottom-impress`,
  MESSAGE_BOTTOM_CLICK: `${BASE_URL_ANALYTIC2}/content-pushmessage/bottom-click`,
  EXPORT_COUNTER_MESSAGE: param => `${BASE_URL_ANALYTIC2}/content-pushmessage/total/${param}`,
  EXPORT_MESSAGE_IMPRESS_CLICK: param => `${BASE_URL_ANALYTIC2}/content-pushmessage/sent-impress-click/${param}`,
  EXPORT_MESSAGE_TOP_IMPRESS: param => `${BASE_URL_ANALYTIC2}/content-pushmessage/top-impress/${param}`,
  EXPORT_MESSAGE_TOP_CLICK: param => `${BASE_URL_ANALYTIC2}/content-pushmessage/top-click/${param}`,
  EXPORT_MESSAGE_BOTTOM_IMPRESS: param => `${BASE_URL_ANALYTIC2}/content-pushmessage/bottom-impress/${param}`,
  EXPORT_MESSAGE_BOTTOM_CLICK: param => `${BASE_URL_ANALYTIC2}/content-pushmessage/bottom-click/${param}`,
  SHARE_COUNTER_MESSAGE: `${BASE_URL_ANALYTIC2}/content-pushmessage/total/share`,
  SHARE_MESSAGE_IMPRESS_CLICK: `${BASE_URL_ANALYTIC2}/content-pushmessage/sent-impress-click/share`,
  SHARE_MESSAGE_TOP_IMPRESS: `${BASE_URL_ANALYTIC2}/content-pushmessage/top-impress/share`,
  SHARE_MESSAGE_TOP_CLICK: `${BASE_URL_ANALYTIC2}/content-pushmessage/top-click/share`,
  SHARE_MESSAGE_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC2}/content-pushmessage/bottom-impress/share`,
  SHARE_MESSAGE_BOTTOM_CLICK: `${BASE_URL_ANALYTIC2}/content-pushmessage/bottom-click/share`,
  // Content Offer
  CONTENT_OFFER_TOTAL: `${BASE_URL_ANALYTIC2}/content-offer/total`,
  CONTENT_OFFER_CLICK_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/click-redeem`,
  CONTENT_OFFER_TOP_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/top-redeem`,
  CONTENT_OFFER_BOTTOM_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/bottom-redeem`,
  CONTENT_OFFER_TOP_SALES: `${BASE_URL_ANALYTIC2}/content-offer/top-sales-value`,
  CONTENT_OFFER_BOTTON_SALES: `${BASE_URL_ANALYTIC2}/content-offer/bottom-sales-value`,
  CONTENT_OFFER_TOP_MEMBER: `${BASE_URL_ANALYTIC2}/content-offer/top-redeem`,
  CONTENT_OFFER_TOP_AVG: `${BASE_URL_ANALYTIC2}/content-offer/top-avg-transaction`,
  EXPORT_OFFER_CLICK_REDEEM: param => `${BASE_URL_ANALYTIC2}/content-offer/click-redeem/${param}`,
  EXPORT_OFFER_TOP_REDEEM: param => `${BASE_URL_ANALYTIC2}/content-offer/top-redeem/${param}`,
  EXPORT_OFFER_BOTTOM_REDEEM: param => `${BASE_URL_ANALYTIC2}/content-offer/bottom-redeem/${param}`,
  EXPORT_OFFER_TOP_SALES: param => `${BASE_URL_ANALYTIC2}/content-offer/top-sales-value/${param}`,
  EXPORT_OFFER_BOTTON_SALES: param => `${BASE_URL_ANALYTIC2}/content-offer/bottom-sales-value/${param}`,
  EXPORT_OFFER_TOP_AVG: param => `${BASE_URL_ANALYTIC2}/content-offer/top-avg-transaction/${param}`,
  EXPORT_OFFER_COUNTER: param => `${BASE_URL_ANALYTIC2}/content-offer/total/${param}`,
  SHARE_COUNTER_OFFER: `${BASE_URL_ANALYTIC2}/content-offer/total/share`,
  SHARE_OFFER_CLICK_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/click-redeem/share`,
  SHARE_OFFER_TOP_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/top-redeem/share`,
  SHARE_OFFER_BOTTOM_REDEEM: `${BASE_URL_ANALYTIC2}/content-offer/bottom-redeem/share`,
  SHARE_OFFER_TOP_SALES: `${BASE_URL_ANALYTIC2}/content-offer/top-sales-value/share`,
  SHARE_OFFER_BOTTON_SALE: `${BASE_URL_ANALYTIC2}/content-offer/bottom-sales-value/share`,
  SHARE_OFFER_TOP_AVG: `${BASE_URL_ANALYTIC2}/content-offer/top-avg-transaction/share`,
  // Help
  SHARE_HELP_USER_GUIDE: `${BASE_URL_ANALYTIC2}/help/user-guide/share`,
  SHARE_HELP_FAQ: `${BASE_URL_ANALYTIC2}/help/faq/share`,
  SHARE_HELP_METRIC: `${BASE_URL_ANALYTIC2}/help/metric/share`,
  // Sales
  SUMMARY: `${BASE_URL_ANALYTIC2}/sales/summary`,
  TOP_STORE: `${BASE_URL_ANALYTIC2}/sales/top-store`,
  BOTTOM_STORE: `${BASE_URL_ANALYTIC2}/sales/bottom-store`,
  EXPORT_SALES_SUMMARY: param => `${BASE_URL_ANALYTIC2}/sales/summary/${param}`,
  EXPORT_TOP_STORE: param => `${BASE_URL_ANALYTIC2}/sales/top-store/${param}`,
  EXPORT_BOTTOM_STORE: param => `${BASE_URL_ANALYTIC2}/sales/bottom-store/${param}`,
  SHARE_SALES_SUMMARY: `${BASE_URL_ANALYTIC2}/sales/summary/share`,
  SHARE_SALES_TOP: `${BASE_URL_ANALYTIC2}/sales/top-store/share`,
  SHARE_SALES_BOTTOM: `${BASE_URL_ANALYTIC2}/sales/bottom-store/share`
}

export const API_DIGITAL_COUPON = {
  LIST_COUPON_SETTING: `${BASE_URL_LOYALITY}/coupon-setting`,
  DETAIL_COUPON_SETTING: id => `${BASE_URL_LOYALITY}/coupon-setting/detail/${id}`,
  LIST_RAFFLE: `${BASE_URL_LOYALITY}/raffle`,
  DETAIL_RAFFLE: id => `${BASE_URL_LOYALITY}/raffle/${id}`,
  LIST_RAFFLE_COUPON: `${BASE_URL_LOYALITY}/raffle/coupon-list`,
  CREATE_RAFFLE: `${BASE_URL_LOYALITY}/raffle/create`,
  DESTROY_RAFFLE: id => `${BASE_URL_LOYALITY}/raffle/${id}`,
  EXPORT_RAFFLE: id => `${BASE_URL_LOYALITY}/raffle/export/${id}`,
  GET_PROMO_CODE_COUPON: `${BASE_URL_CORE}/get-coupon-setting-info`,
  LIST_STORE: `${BASE_URL_LOYALITY}/store?is_all=true`,
  CREATE_COUPON_SETTING: `${BASE_URL_LOYALITY}/coupon-setting`,
  UPDATE_COUPON_SETTING: id => `${BASE_URL_LOYALITY}/coupon-setting/${id}`,
  LIST_COUPON_LOG: `${BASE_URL_LOYALITY}/coupon-log`,
  DETAIL_COUPON_LOG: id => `${BASE_URL_LOYALITY}/coupon-log/detail/${id}`,
  DETAIL_COUPON_LOG_COUPON_LIST: id => `${BASE_URL_LOYALITY}/coupon-log/coupon-list/${id}`,
  EXPORT_COUPON_LOG: param => `${BASE_URL_LOYALITY}/coupon-log/xlsx${param}`,
  DOWNLOAD_LIST: `${BASE_URL_LOYALITY}/export/download-list`,
  GET_ORDER_ID: (id) => `${BASE_URL_ORDER}/order/detail/receipt-code?receipt_code=${id}`
}

export const API_LOG = {
  COSTUMER_LOGIN_LOG: `${BASE_URL_LOGGER}/log-data/login-log`,
  VOUCHER_USED_LOG: `${BASE_URL_LOGGER}/log-data/voucher-log`
}

export const API_FREE_GIFT = {
  LIST_FREE_GIFT: `${BASE_URL_LOYALITY}/free-gift-promotion`,
  DETAIL_FREE_GIFT: (id) => `${BASE_URL_LOYALITY}/free-gift-promotion/detail/${id}`,
  LIST_PRODUCT: `${BASE_URL_STORE}/product?is_all=true`,
  LIST_REGION: `${BASE_URL_STORE}/store-regions/list`,
  LIST_STORE_BY_REGION: `${BASE_URL_STORE}/store/by-region`,
  DELETE_FREE_GIFT: (id) => `${BASE_URL_LOYALITY}/free-gift-promotion/delete/${id}`,
  CREATE_FREE_GIFT: `${BASE_URL_LOYALITY}/free-gift-promotion/create`,
  UPDATE_FREE_GIFT: (id) => `${BASE_URL_LOYALITY}/free-gift-promotion/update/${id}`,
  BULK_UPLOAD_STORE: `${BASE_URL_LOYALITY}/free-gift-promotion/bulk-upload-stores`
}

export const API_FREE_GIFT_REPORT = {
  LIST_FREE_GIFT: `${BASE_URL_ORDER}/order/list-free-gift`,
  EXPORT_FREE_GIFT: (param) => `${BASE_URL_ORDER}/order/free-gift-report/export${param}`,
  DETAIL_FREE_GIFT: (id) => `${BASE_URL_ORDER}/order/free-gift-purchased-items/${id}`
}

export const API_REPORT_ORDER = {
  LIST_ORDER: `${BASE_URL_REPORT}/orders`,
  EXPORT_ORDER: `${BASE_URL_REPORT}/orders-export`,
  LIST_ORDER_ITEM: (id) => `${BASE_URL_REPORT}/orders/items/${id}`,
  DETAIL_ORDER: (id) => `${BASE_URL_REPORT}/orders/detail/${id}`,
  PREVIEW_ORDER: (id) => `${BASE_URL_REPORT}/order/print-detail/${id}`,
  GET_NEW_DRIVER: (id) => `${BASE_URL_REPORT}/order/get-new-driver/${id}`,
  UPDATE_UNPICK_ALL: (id) => `${BASE_URL_REPORT}/order/cancel/${id}`,
  GET_LIST_CANCEL_REASON: `${BASE_URL_REPORT}/order/cancel-reason`,
  UPDATE_ORDER_ITEMS: (id) => `${BASE_URL_REPORT}/order/cancel-reason-items/${id}`
}

export const API_REPORT_ALP = {
  LIST_ALP: `${BASE_URL_REPORT}/alp`,
  LIST_RECAP: `${BASE_URL_REPORT}/recap`,
  EXPORT_ORDER: `${BASE_URL_REPORT}/alp-export`,
  EXPORT_RECAP: `${BASE_URL_REPORT}/recap-export`,
  LIST_ALP_ITEM: (transactionType, id) => `${BASE_URL_REPORT}/alp/items/${transactionType}/${id}`,
  DETAIL_ORDER: (id) => `${BASE_URL_REPORT}/alp/detail/${id}`
}

export const API_CHAT = {
  LIST_CHAT_LIVE: `${BASE_URL_CHAT}/chatlive/list`,
  CREATE_CHAT_LIVE: `${BASE_URL_CHAT}/chatlive/create`,
  DETAIL_CHAT_LIVE: id => `${BASE_URL_CHAT}/chatlive/detail/conversation/${id}`,
  USER_CHAT_LIVE: id => `${BASE_URL_CHAT}/chatlive/detail/${id}`,
  GET_UNREAD_COUNT: `${BASE_URL_CHAT}/chatlive/get-count-by-status?status=1`,
  END_CHAT_LIVE: id => `${BASE_URL_CHAT}/chatlive/end-chat/${id}`,
  LIST_TEMPLATE_CHAT_LIVE: `${BASE_URL_CHAT}/chatlive-template`,
  LIST_TEMPLATE_DETAIL_CHAT_LIVE: id => `${BASE_URL_CHAT}/chatlive-template/detail/${id}`,
  CREATE_TEMPLATE_CHAT_LIVE: `${BASE_URL_CHAT}/chatlive-template/create`,
  DELETE_TEMPLATE_CHAT_LIVE: id => `${BASE_URL_CHAT}/chatlive-template/delete/${id}`,
  EDIT_TEMPLATE_CHAT_LIVE: id => `${BASE_URL_CHAT}/chatlive-template/update/${id}`,
  SUBSCRIBE_CHAT_LIVE: `${BASE_URL_ACCOUNT}/user/subscribe/chat-live`,
  EXPORT_CHAT_LIVE: `${BASE_URL_CHAT}/chatlive/export/xlsx`,
  UNSUBSCRIBE_CHAT_LIVE: `${BASE_URL_ACCOUNT}/user/unsubscribe/chat-live`,
  LIST_CATEGORY: `${BASE_URL_CHAT}/chatbot/tag/parent-categories`
}

export const API_CHAT_BOT = {
  GET_CHAT_BOT: `${BASE_URL_CHAT}/chatbot/configurations`,
  GET_CHAT_BOT_DETAIL: (id) => `${BASE_URL_CHAT}/chatbot/tag/details/${id}`,
  UPDATE_CHAT_BOT: `${BASE_URL_CHAT}/chatbot/configurations/edit`
}

export const API_MASTER_PROMO = {
  LIST_MASTER_PROMO: `${BASE_URL_STORE}/master-promo/list`,
  DETAIL_MASTER_PROMO: (id) => `${BASE_URL_STORE}/master-promo/detail/${id}`,
  UPDATE_MASTER_PROMO: (id) => `${BASE_URL_STORE}/master-promo/update/${id}`,
  PREVIEW_BULK_MASTER_PROMO: `${BASE_URL_STORE}/master-promo/bulk-upload/preview`,
  BULK_MASTER_PROMO: `${BASE_URL_STORE}/master-promo/update/bulk`,
  STATUS_BULK: `${BASE_URL_STORE}/master-promo/update/bulk/status`
}

export const API_TOP_UP_BILLS = {
  LIST_DIGITAL_BANNER: `${BASE_URL_LOYALITY}/digital-banner`,
  DETAIL_DIGITAL_BANNER: id => `${BASE_URL_LOYALITY}/digital-banner/detail/${id}`,
  UPDATE_DIGITAL_BANNER: id => `${BASE_URL_LOYALITY}/digital-banner/update/${id}`,
  CREATE_DIGITAL_BANNER: `${BASE_URL_LOYALITY}/digital-banner/create`,
  UPDATE_POSITION_DIGITAL_BANNER: `${BASE_URL_LOYALITY}/digital-banner/update-position`,
  GET_CTA_SETTING: `${BASE_URL_ACCOUNT}/digital-banner`,
  UPDATE_CTA_SETTING: `${BASE_URL_ACCOUNT}/digital-banner/update`,
  LIST_DIGITAL_PRODUCT: `${BASE_URL_STORE}/digital-product`,
  LIST_ORDER_MANAGEMENT_DIGITAL: `${BASE_URL_ORDER}/order-digital/list`,
  DETAIL_ORDER_MANAGEMENT_DIGITAL: id => `${BASE_URL_ORDER}/order-digital/detail/${id}`,
  EXPORT_ORDER_MANAGEMENT_DIGITAL: `${BASE_URL_ORDER}/order-digital/export/xlsx`,
  RETRY_ORDER_MANAGEMENT_DIGITAL: orderID => `${BASE_URL_ORDER}/order-digital/retry/${orderID}`,
  BULK_ORDER_MANAGEMENT_DIGITAL: `${BASE_URL_ORDER}/order-digital/reconciliation/process-file`,
  BULK_UPDATE_ORDER_MANAGEMENT_DIGITAL: `${BASE_URL_ORDER}/order-digital/reconciliation/execute`,
  CANCEL_ORDER: orderID => `${BASE_URL_ORDER}/order-digital/cancel/${orderID}`
}

export const API_PLANOGRAM_ANALYTICS = {
  LIST_PLANOGRAM: `${BASE_URL_PANDORA}/planogram-analytic/list-planogram`,
  LIST_PLANOGRAM_PRODUCT: `${BASE_URL_PANDORA}/planogram-analytic/list-planogram-product`,
  GET_SUMMARY: `${BASE_URL_PANDORA}/planogram-analytic/summary`,
  TOP_IMPRESSION: `${BASE_URL_PANDORA}/planogram-analytic/planogram?metrics=impression&sort=desc`,
  BOTTOM_IMPRESSION: `${BASE_URL_PANDORA}/planogram-analytic/planogram?metrics=impression&sort=asc`,
  TOP_CLICK: `${BASE_URL_PANDORA}/planogram-analytic/planogram?metrics=click&sort=desc`,
  BOTTOM_CLICK: `${BASE_URL_PANDORA}/planogram-analytic/planogram?metrics=click&sort=asc`,
  TOP_CART: `${BASE_URL_PANDORA}/planogram-analytic/planogram?metrics=cart&sort=desc`,
  BOTTOM_CART: `${BASE_URL_PANDORA}/planogram-analytic/planogram?metrics=cart&sort=asc`,
  TOP_SALES: `${BASE_URL_PANDORA}/planogram-analytic/planogram-sales?sort=desc`,
  BOTTOM_SALES: `${BASE_URL_PANDORA}/planogram-analytic/planogram-sales?sort=asc`,
  TOP_IMPRESSION_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product?metrics=impression&sort=desc`,
  BOTTOM_IMPRESSION_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product?metrics=impression&sort=asc`,
  TOP_CLICK_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product?metrics=click&sort=desc`,
  BOTTOM_CLICK_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product?metrics=click&sort=asc`,
  TOP_CART_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product?metrics=cart&sort=desc`,
  BOTTOM_CART_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product?metrics=cart&sort=asc`,
  TOP_SALES_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product-sales?sort=desc`,
  BOTTOM_SALES_ITEMS: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product-sales?sort=asc`,
  SHARE_EXPORT_PLANOGRAM_ITEMS_PRODUCT: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product`,
  SHARE_EXPORT_PLANOGRAM_ITEMS_PRODUCT_SALES: `${BASE_URL_PANDORA}/planogram-analytic/planogram-product-sales`,
  SHARE_PLANOGRAM: `${BASE_URL_PANDORA}/planogram-analytic/planogram/share`,
  SHARE_PLANOGRAM_SALES: `${BASE_URL_PANDORA}/planogram-analytic/planogram-sales/share`,
  EXPORT_PLANOGRAM: `${BASE_URL_PANDORA}/planogram-analytic/planogram/export`,
  EXPORT_PLANOGRAM_SALES: `${BASE_URL_PANDORA}/planogram-analytic/planogram-sales/export`
}

export const API_HELPDESK = {
  LIST_TICKET: `${BASE_URL_HELPDESK}/ticket`,
  CREATE_TICKET: `${BASE_URL_HELPDESK}/ticket`,
  DETAIL_TICKET: (id) => `${BASE_URL_HELPDESK}/ticket/${id}`,
  UPDATE_TICKET: (id) => `${BASE_URL_HELPDESK}/ticket/${id}/close`,
  UPDATE_DETAIL_TICKET: (id) => `${BASE_URL_HELPDESK}/ticket/${id}`,
  BULK_UPDATE_TICKET: `${BASE_URL_HELPDESK}/ticket/close`,
  CREATE_ASSIGNEE: (id) => `${BASE_URL_HELPDESK}/ticket/${id}/assignee`,
  UPDATE_ASSIGNEE: (id, ticket_id) => `${BASE_URL_HELPDESK}/ticket/${ticket_id}/assignee/${id}`,

  LIST_CHANNEL: `${BASE_URL_HELPDESK}/channel`,
  LIST_ROLE: `${BASE_URL_HELPDESK}/role`,
  LIST_CATEGORY: `${BASE_URL_HELPDESK}/category`,
  CREATE_CATEGORY: `${BASE_URL_HELPDESK}/category`,
  DETAIL_CATEGORY: (id) => `${BASE_URL_HELPDESK}/category/${id}`,
  UPDATE_CATEGORY: (id) => `${BASE_URL_HELPDESK}/category/${id}`,
  DELETE_CATEGORY: (id) => `${BASE_URL_HELPDESK}/category/${id}`,
  
  LIST_ASSIGNEE: `${BASE_URL_ACCOUNT}/email-mapping/get-for-assignee`,
  LIST_MAPPING_EMAIL: `${BASE_URL_ACCOUNT}/email-mapping`,
  LIST_MAPPING_EMAIL_USER: `${BASE_URL_ACCOUNT}/user/list`,
  CREATE_MAPPING_EMAIL: `${BASE_URL_ACCOUNT}/email-mapping`,
  DETAIL_MAPPING_EMAIL: (id) => `${BASE_URL_ACCOUNT}/email-mapping/${id}`,
  UPDATE_MAPPING_EMAIL: (id) => `${BASE_URL_ACCOUNT}/email-mapping/${id}`,
  DELETE_MAPPING_EMAIL: (id) => `${BASE_URL_ACCOUNT}/email-mapping/${id}`,

  LIST_STORE: `${BASE_URL_HELPDESK}/store`
}

export const API_REPORT_HELPDESK = {
  LIST_REPORT: `${BASE_URL_REPORT}/helpdesk`,
  EXPORT_REPORT: `${BASE_URL_REPORT}/helpdesk-export`,
  LIST_SUMMARY: `${BASE_URL_REPORT}/helpdesk-summary`
}
