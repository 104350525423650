import { apiGetAuth, apiGetAuthWithCustomHeadersResponseType } from '@/utils/api'
import { API_REPORT_HELPDESK, API_STORE } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'
import { exportCustomHeaderAndResponseType } from '@/utils/helper'


const state = () => {
  return {
    helpdesk: { data: [] },
    summary: { data: [] },
    store: { data: [] },
    data_modal_add_edit: {},
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_HELPDESK (state, payload) {
    state.helpdesk = payload
  },
  SET_SUMMARY (state, payload) {
    state.summary = payload
  },
  SET_STORE (state, payload) {
    state.store = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListHelpdesk (context, payload) {
    
    try {
      const response = await apiGetAuth(API_REPORT_HELPDESK.LIST_REPORT, payload)
      
      context.commit('SET_HELPDESK', response.data.data)
      const meta = {...payload, store_id: payload?.store_id?.includes(",") ? undefined : payload?.store_id}
      context.commit('SET_META_FILTER', meta)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getListSummary (context, payload) {
    try {
      const response = await apiGetAuth(API_REPORT_HELPDESK.LIST_SUMMARY, payload)
      context.commit('SET_SUMMARY', response.data.data)
      const meta = {...payload, store_id: payload?.store_id?.includes(",") ? undefined : payload?.store_id}
      context.commit('SET_META_FILTER', meta)
    } catch (error){
      notificationDanger(error)
    }
  },
  async exportHelpdesk (_, payload) {
    console.log(payload)
    
    try {
      const response = await apiGetAuthWithCustomHeadersResponseType(API_REPORT_HELPDESK.EXPORT_REPORT, payload)
      exportCustomHeaderAndResponseType(response.data, `Export Report Helpdesk.xlsx`)
    } catch (error){
      console.log(error)
      
      notificationDanger(error)
    }
  },

  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE.LIST_STORE, payload)
      context.commit('SET_STORE', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_helpdesk (state) {
    return state.helpdesk
  },
  list_summary (state) {
    return state.summary
  },
  list_store (state) {
    return state.store
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
