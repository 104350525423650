import { apiGetAuth, apiPostAuth } from '@/utils/api'
import { API_MASTER_DATA } from '@/utils/api-url'
import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    stores: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_STORE (state, payload) {
    state.stores = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async syncStore (context, payload) {
    try {
      const response = await apiPostAuth(API_MASTER_DATA.SYNC_STORE, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListAllStore (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.LIST_ALL_STORE, payload)
      context.commit('SET_STORE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.LIST_STORE, payload)
      context.commit('SET_STORE', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'Store', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_store (state) {
    return state.stores
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
