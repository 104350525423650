import {apiDeleteAuth, apiGetAuth, apiPostAuth} from '@/utils/api'
import { API_CAMPAIGN } from '@/utils/api-url'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    notificationOffer: { data: [] },
    notificationOfferAll: { data: [] },
    detail_notification_offer: { data: {}},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_NOTIFICATION_OFFER (state, payload) {
    state.notificationOffer = payload
  },
  SET_NOTIFICATION_OFFER_ALL (state, payload) {
    state.notificationOfferAll = payload
  },
  SET_DETAIL_NOTIFICATION_OFFER (state, payload) {
    state.detail_notification_offer = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListNotificationOffer (context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.NOTIFICATION_OFFER, payload)
      context.commit('SET_NOTIFICATION_OFFER', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListNotificationOfferAll (context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.LIST_ALL_NOTIF_OFFER, payload)
      context.commit('SET_NOTIFICATION_OFFER_ALL', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async deleteNotificationOffer (context, payload) {
    try {
      const response = await apiDeleteAuth(API_CAMPAIGN.DELETE_NOTIFICATION_OFFER(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async createNotificationOffer (context, payload) {
    try {
      const response = await apiPostAuth(API_CAMPAIGN.NOTIFICATION_OFFER, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_notification_offer (state) {
    return state.notificationOffer
  },
  list_notification_offer_all (state) {
    return state.notificationOfferAll
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
