import { apiGetAuth, apiPutAuth } from '@/utils/api'
import { API_MASTER_DATA } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    setting_app_android: { data: {} },
    setting_app_ios: { data: {} },
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_SETTING_APP_ANDROID(state, payload) {
    state.setting_app_android = payload
  },
  SET_SETTING_APP_IOS(state, payload) {
    state.setting_app_ios = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getDetailSettingAppAndroid (context) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.SETTING_APP_ANDROID)
      context.commit('SET_SETTING_APP_ANDROID', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailSettingAppIos (context) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.SETTING_APP_IOS)
      context.commit('SET_SETTING_APP_IOS', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateSettingApp (context, payload) {
    try {
      const response = await apiPutAuth(API_MASTER_DATA.SETTING_APP(payload.type), payload.reqBody)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  setting_app_android (state) {
    return state.setting_app_android
  },
  setting_app_ios (state) {
    return state.setting_app_ios
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
