import { API_PLANOGRAM_ANALYTICS } from "@/utils/api-url"
import { apiGetAuth, apiPostAuth } from '@/utils/api'
import { notificationDanger } from '@/utils/notification'

const state = () => {
  return {
    planogram: {
      isLoading: false,
      data: {}
    },
    planogram_product: {
      isLoading: false,
      data: []
    },
    summary: {
      isLoading: false,
      data: {}
    },
    top_impression: {
      isLoading: false,
      data: []
    },
    bottom_impression: {
      isLoading: false,
      data: []
    },
    top_click: {
      isLoading: false,
      data: []
    },
    bottom_click: {
      isLoading: false,
      data: []
    },
    top_cart: {
      isLoading: false,
      data: []
    },
    bottom_cart: {
      isLoading: false,
      data: []
    },
    top_sales: {
      isLoading: false,
      data: []
    },
    bottom_sales: {
      isLoading: false,
      data: []
    },
    top_impression_items: {
      isLoading: false,
      data: []
    },
    bottom_impression_items: {
      isLoading: false,
      data: []
    },
    top_click_items: {
      isLoading: false,
      data: []
    },
    bottom_click_items: {
      isLoading: false,
      data: []
    },
    top_cart_items: {
      isLoading: false,
      data: []
    },
    bottom_cart_items: {
      isLoading: false,
      data: []
    },
    top_sales_items: {
      isLoading: false,
      data: []
    },
    bottom_sales_items: {
      isLoading: false,
      data: []
    },
    
    tab_id: '0',
    meta_filter: {},
    meta_filter_by_planogram: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_LIST_PLANOGRAM(state, payload) {
    state.planogram = payload
  },
  SET_LIST_PLANOGRAM_PRODUCT(state, payload) {
    state.planogram_product = payload
  },
  SET_LIST_SUMMARY(state, payload) {
    state.summary = payload
  },
  SET_LIST_TOP_IMPRESSION(state, payload) {
    state.top_impression = payload
  },
  SET_LIST_BOTTOM_IMPRESSION(state, payload) {
    state.bottom_impression = payload
  },
  SET_LIST_TOP_CLICK(state, payload) {
    state.top_click = payload
  },
  SET_LIST_BOTTOM_CLICK(state, payload) {
    state.bottom_click = payload
  },
  SET_LIST_TOP_CART(state, payload) {
    state.top_cart = payload
  },
  SET_LIST_BOTTOM_CART(state, payload) {
    state.bottom_cart = payload
  },
  SET_LIST_TOP_SALES(state, payload) {
    state.top_sales = payload
  },
  SET_LIST_BOTTOM_SALES(state, payload) {
    state.bottom_sales = payload
  },
  SET_LIST_TOP_IMPRESSION_ITEMS(state, payload) {
    state.top_impression_items = payload
  },
  SET_LIST_BOTTOM_IMPRESSION_ITEMS(state, payload) {
    state.bottom_impression_items = payload
  },
  SET_LIST_TOP_CLICK_ITEMS(state, payload) {
    state.top_click_items = payload
  },
  SET_LIST_BOTTOM_CLICK_ITEMS(state, payload) {
    state.bottom_click_items = payload
  },
  SET_LIST_TOP_CART_ITEMS(state, payload) {
    state.top_cart_items = payload
  },
  SET_LIST_BOTTOM_CART_ITEMS(state, payload) {
    state.bottom_cart_items = payload
  },
  SET_LIST_TOP_SALES_ITEMS(state, payload) {
    state.top_sales_items = payload
  },
  SET_LIST_BOTTOM_SALES_ITEMS(state, payload) {
    state.bottom_sales_items = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_META_FILTER_BY_PLANOGRAM (state, payload) {
    state.meta_filter_by_planogram = payload
  },
  SET_TAB_ID (state, payload) {
    state.tab_id = payload
  }
}

const actions = {
  async getListPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_PLANOGRAM', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.LIST_PLANOGRAM, payload)
      context.commit('SET_LIST_PLANOGRAM', { isLoading: false, data: data })
    } catch (error){
      context.commit('SET_LIST_PLANOGRAM', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListPlanogramProduct (context, payload) {
    context.commit('SET_LIST_PLANOGRAM_PRODUCT', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.LIST_PLANOGRAM_PRODUCT, payload)
      context.commit('SET_LIST_PLANOGRAM_PRODUCT', { isLoading: false, data })
    } catch (error){
      context.commit('SET_LIST_PLANOGRAM_PRODUCT', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getSummary(context, payload) {
    context.commit('SET_LIST_SUMMARY', { isLoading: true, data: [] })
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.GET_SUMMARY, payload)
      context.commit('SET_LIST_SUMMARY', { isLoading: false, data })
    } catch (error) {
      context.commit('SET_LIST_SUMMARY', { isLoading: false, data: [] })
    }
  },
  async getListTopImpressionPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_TOP_IMPRESSION', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_IMPRESSION, payload)
      context.commit('SET_LIST_TOP_IMPRESSION', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_IMPRESSION', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomImpressionPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_BOTTOM_IMPRESSION', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_IMPRESSION, payload)
      context.commit('SET_LIST_BOTTOM_IMPRESSION', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_IMPRESSION', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopImpressionPlanogramItems (context, payload) {
    context.commit('SET_LIST_TOP_IMPRESSION_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_IMPRESSION_ITEMS, payload)
      context.commit('SET_LIST_TOP_IMPRESSION_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_IMPRESSION_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomImpressionPlanogramItems (context, payload) {
    context.commit('SET_LIST_BOTTOM_IMPRESSION_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_IMPRESSION_ITEMS, payload)
      context.commit('SET_LIST_BOTTOM_IMPRESSION_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_IMPRESSION_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopClickPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_TOP_CLICK', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_CLICK, payload)
      context.commit('SET_LIST_TOP_CLICK', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_CLICK', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomClickPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_BOTTOM_CLICK', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_CLICK, payload)
      context.commit('SET_LIST_BOTTOM_CLICK', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_CLICK', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopClickPlanogramItems (context, payload) {
    context.commit('SET_LIST_TOP_CLICK_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_CLICK_ITEMS, payload)
      context.commit('SET_LIST_TOP_CLICK_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_CLICK_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomClickPlanogramItems (context, payload) {
    context.commit('SET_LIST_BOTTOM_CLICK_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_CLICK_ITEMS, payload)
      context.commit('SET_LIST_BOTTOM_CLICK_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_CLICK_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopCartPlanogramItems (context, payload) {
    context.commit('SET_LIST_TOP_CART_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_CART_ITEMS, payload)
      context.commit('SET_LIST_TOP_CART_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_CART_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomCartPlanogramItems (context, payload) {
    context.commit('SET_LIST_BOTTOM_CART_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_CART_ITEMS, payload)
      context.commit('SET_LIST_BOTTOM_CART_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_CART_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopSalesPlanogramItems (context, payload) {
    context.commit('SET_LIST_TOP_SALES_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_SALES_ITEMS, payload)
      context.commit('SET_LIST_TOP_SALES_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_SALES_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomSalesPlanogramItems (context, payload) {
    context.commit('SET_LIST_BOTTOM_SALES_ITEMS', {data: [], isLoading: true})
    try {
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_SALES_ITEMS, payload)
      context.commit('SET_LIST_BOTTOM_SALES_ITEMS', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_SALES_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopCartPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_TOP_CART', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_CART, payload)
      context.commit('SET_LIST_TOP_CART', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_CART', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomCartPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_BOTTOM_CART', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_CART, payload)
      context.commit('SET_LIST_BOTTOM_CART', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_CART', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopSalesPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_TOP_SALES', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.TOP_SALES, payload)
      context.commit('SET_LIST_TOP_SALES', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_TOP_SALES', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListBottomSalesPlanogram (context, payload) {
    try {
      context.commit('SET_LIST_BOTTOM_SALES', {data: [], isLoading: true})
      const { data } = await apiGetAuth(API_PLANOGRAM_ANALYTICS.BOTTOM_SALES, payload)
      context.commit('SET_LIST_BOTTOM_SALES', { isLoading: false, data: data.data })
    } catch (error){
      context.commit('SET_LIST_BOTTOM_SALES', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async exportSummary(context, payload) {
    try {
      const response = await apiGetAuth(`${API_PLANOGRAM_ANALYTICS.GET_SUMMARY}/export/${payload.type}`, payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async sharePlanogramSummary(context, payload) {
    try {
      const response = await apiPostAuth(`${API_PLANOGRAM_ANALYTICS.GET_SUMMARY}/share`, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportPlanogramProduct(context, payload) {
    try {
      const response = await apiGetAuth(`${API_PLANOGRAM_ANALYTICS.SHARE_EXPORT_PLANOGRAM_ITEMS_PRODUCT}/export/${payload.type}`, payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async sharePlanogramProduct(context, payload) {
    try {
      const response = await apiPostAuth(`${API_PLANOGRAM_ANALYTICS.SHARE_EXPORT_PLANOGRAM_ITEMS_PRODUCT}/share`, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportPlanogramProductSales(context, payload) {
    console.log({payloads: payload})
    try {
      const response = await apiGetAuth(`${API_PLANOGRAM_ANALYTICS.SHARE_EXPORT_PLANOGRAM_ITEMS_PRODUCT_SALES}/export/${payload.type}`, payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async sharePlanogramProductSales(context, payload) {
    try {
      const response = await apiPostAuth(`${API_PLANOGRAM_ANALYTICS.SHARE_EXPORT_PLANOGRAM_ITEMS_PRODUCT_SALES}/share`, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async sharePlanogram(context, payload) {
    try {
      const response = await apiPostAuth(API_PLANOGRAM_ANALYTICS.SHARE_PLANOGRAM, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async sharePlanogramSales(context, payload) {
    try {
      const response = await apiPostAuth(API_PLANOGRAM_ANALYTICS.SHARE_PLANOGRAM_SALES, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportByPlanogram(context, payload) {
    try {
      const response = await apiGetAuth(`${API_PLANOGRAM_ANALYTICS.EXPORT_PLANOGRAM}/${payload.type}`, payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportByPlanogramSales(context, payload) {
    try {
      const response = await apiGetAuth(`${API_PLANOGRAM_ANALYTICS.EXPORT_PLANOGRAM_SALES}/${payload.type}`, payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_planogram (state) {
    return state.planogram
  },
  list_planogram_product (state) {
    return state.planogram_product
  },
  list_summary (state) {
    return state.summary
  },
  list_top_impression (state) {
    return state.top_impression
  },
  list_bottom_impression (state) {
    return state.bottom_impression
  },
  list_top_impression_items (state) {
    return state.top_impression_items
  },
  list_bottom_impression_items (state) {
    return state.bottom_impression_items
  },
  list_top_click (state) {
    return state.top_click
  },
  list_bottom_click (state) {
    return state.bottom_click
  },
  list_top_cart (state) {
    return state.top_cart
  },
  list_bottom_cart (state) {
    return state.bottom_cart
  },
  list_top_sales (state) {
    return state.top_sales
  },
  list_bottom_sales (state) {
    return state.bottom_sales
  },
  list_top_click_items (state) {
    return state.top_click_items
  },
  list_bottom_click_items (state) {
    return state.bottom_click_items
  },
  list_top_cart_items (state) {
    return state.top_cart_items
  },
  list_bottom_cart_items (state) {
    return state.bottom_cart_items
  },
  list_top_sales_items (state) {
    return state.top_sales_items
  },
  list_bottom_sales_items (state) {
    return state.bottom_sales_items
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter_by_planogram (state) {
    return state.meta_filter_by_planogram
  },
  tab_id (state) {
    return state.tab_id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
