import { apiPostNonAuth } from '@/utils/api'
import { API_MOBILE } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async resetPassword(context, payload) {
    try {
      const response = await apiPostNonAuth(API_MOBILE.RESET_PASSWORD, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(err)
    }
  },
  async emailVerif(context, payload) {
    try {
      const response = await apiPostNonAuth(API_MOBILE.EMAIL_VERIFICATION, payload)
      return response.data
    } catch (err) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        message: err.response.data.message,
        loading: false
      })
      notificationDanger(err)
    }
  }
}

const getters = {
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
