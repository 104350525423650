import { apiGetAuth } from '@/utils/api'
import { API_USER_MANAGEMENT } from '@/utils/api-url'
import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    list_log_history: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_LOG_HISTORY (state, payload) {
    state.list_log_history = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getLogHistory (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.USER_LOG_HISTORY, payload)
      context.commit('SET_LOG_HISTORY', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'LogHistory', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_log_history (state) {
    return state.list_log_history
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
