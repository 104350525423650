import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_USER_MANAGEMENT } from '@/utils/api-url'
import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    role: { data: [] },
    detail_role: { data: {}},
    permissions: {},
    role_permissions: {},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_ROLE (state, payload) {
    state.role = payload
  },
  SET_DETAIL_ROLE (state, payload) {
    state.detail_role = payload
  },
  SET_LIST_PERMISSIONS (state, payload) {
    state.permissions = payload
  },
  SET_LIST_ROLE_PERMISSIONS (state, payload) {
    state.role_permissions = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListRole (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.LIST_ROLE, payload)
      context.commit('SET_ROLE', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'RoleManagement', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createRole (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.CREATE_ROLE, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async getDetailRole (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_ROLE(payload))
      context.commit('SET_DETAIL_ROLE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateRole (context, payload) {
    try {
      const response = await apiPutAuth(API_USER_MANAGEMENT.UPDATE_ROLE(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async deleteRole (context, payload) {
    try {
      const response = await apiDeleteAuth(API_USER_MANAGEMENT.DELETE_ROLE(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async getListPermissions (context) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.LIST_PERMISSIONS)
      context.commit('SET_LIST_PERMISSIONS', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListRolePermissions (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.LIST_ROLE_PERMISSIONS+`?role_id=${payload}`)
      context.commit('SET_LIST_ROLE_PERMISSIONS', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateRolePermissions (context, payload) {
    console.log(payload)
    
    try {
      const response = await apiPutAuth(API_USER_MANAGEMENT.UPDATE_ROLE_PERMISSIONS, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_role (state) {
    return state.role
  },
  detail_role (state) {
    return state.detail_role
  },
  list_permissions (state) {
    return state.permissions
  },
  list_role_permissions (state) {
    return state.role_permissions
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
