<template>
  <div class="uk-widht-1-1 uk-text-center">
    <span>Copyright 2022 Super Indo. All Rights Reserved.</span>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.uk-text-center {
  margin-top: 50px;
}
 .uk-text-center span {
  font-family: 'jakartaRegular';
  font-weight: 400;
  font-size: 14px;
  color: #FFF;
 }
</style>
