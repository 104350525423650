import { apiGetAuth } from '@/utils/api'
import { API_POLLING_SURVEY } from '@/utils/api-url'
import { exportCsv } from '@/utils/helper'
import { dateStringDashFromDay } from '@/utils/formater'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    response: { data: [] },
    detail_response: {},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_RESPONSE (state, payload) {
    state.response = payload
  },
  SET_RESPONSE_DETAIL (state, payload) {
    state.detail_response = payload
  },
  SET_EXPORT_RESPONSE (state, payload) {
    state.detail_response = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListResponse(context, payload) {
    try {
      const response = await apiGetAuth(API_POLLING_SURVEY.RESPONSE, payload)
      context.commit('SET_RESPONSE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailResponse(context, payload) {
    try {
      const response = await apiGetAuth(API_POLLING_SURVEY.RESPONSE_DETAIL(payload))
      context.commit('SET_RESPONSE_DETAIL', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportResponse(context, payload) {
    try {
      const response = await apiGetAuth(API_POLLING_SURVEY.EXPORT_RESPONSE, payload)
      exportCsv(response.data, `response_-_${dateStringDashFromDay(new Date())}`)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_response (state) {
    return state.response
  },
  detail_response (state) {
    return state.detail_response
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
