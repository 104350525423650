import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_USER_MANAGEMENT } from '@/utils/api-url'
import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    roles: { data: [] },
    users: { data: [] },
    users_all: { data: [] },
    list_log_history: { data: [] },
    detail_user: { data: {}},
    detail_profile: {data: {}},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_ROLE (state, payload) {
    state.roles = payload
  },
  SET_USER (state, payload) {
    state.users = payload
  },
  SET_USER_ALL (state, payload) {
    state.users_all = payload
  },
  SET_LOG_HISTORY (state, payload) {
    state.list_log_history = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_DETAIL_PROFILE(state, payload) {
    state.detail_profile = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListRole (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.LIST_ROLE, payload)
      context.commit('SET_ROLE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListUser (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.LIST_USER, payload)
      context.commit('SET_USER', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'User', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListUserAll (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.LIST_USER_ALL, payload)
      context.commit('SET_USER_ALL', response.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getLogHistory (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.USER_LOG_HISTORY, payload)
      context.commit('SET_LOG_HISTORY', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'LogHistory', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailUser (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_USER(payload))
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailProfile (context) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_PROFILE)
      context.commit('SET_DETAIL_PROFILE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.CREATE_USER, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async updateUser (context, payload) {
    try {
      const response = await apiPutAuth(API_USER_MANAGEMENT.UPDATE_USER(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async deleteUser (context, payload) {
    try {
      const response = await apiDeleteAuth(API_USER_MANAGEMENT.DELETE_USER(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_role (state) {
    return state.roles
  },
  list_user (state) {
    return state.users
  },
  list_user_all (state) {
    return state.users_all
  },
  list_log_history (state) {
    return state.list_log_history
  },
  detail_user (state) {
    return state.detail_user
  },
  detail_profile (state) {
    return state.detail_profile
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
