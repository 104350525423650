import { apiGetAuth, apiPutAuth, apiPostAuth, apiDeleteAuth } from '@/utils/api'
import { API_HELPDESK } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    categories: [],
    categoryDetail: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
  SET_CATEGORY_DETAIL(state, payload) {
    state.categoryDetail = payload
  },
  SET_DATA_MODAL_ADD_EDIT(state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD(state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async listCategories(_, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_CATEGORY, payload)
      return response.data.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createCategory(context, payload) {
    try {
      const response = await apiPostAuth(API_HELPDESK.CREATE_CATEGORY, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCategoryDetail(context, id) {
    try {
      const response = await apiGetAuth(API_HELPDESK.DETAIL_CATEGORY(id))
      context.commit('SET_CATEGORY_DETAIL', response.data.data)
      return response.data.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateCategory(_, { id, data }) {
    try {
      const response = await apiPutAuth(API_HELPDESK.UPDATE_CATEGORY(id), data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async deleteCategory( _, id ) {
    try {
      const response = await apiDeleteAuth(API_HELPDESK.DELETE_CATEGORY(id))
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  categories(state) {
    return state.categories
  },
  categoryDetail(state) {
    return state.categoryDetail
  },
  get_data_modal_add_edit(state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard(state) {
    return state.toggle_modal_add_edit_discard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
