import {apiGetAuth, apiPutAuth, apiPostAuth} from '@/utils/api'
import {API_CONSUMER} from '@/utils/api-url'
import router from '@/router'
import { exportCsv } from '@/utils/helper'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    helpCenter: { data: [] },
    exportHelpCenter: { data: [] },
    listCategoryHc: { data: [] },
    listTypeHc: { data: [] },
    detail_help_center: { data: {}},
    bulk_uploads: { data: []},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_LIST_CATEGORY_HC (state, payload) {
    state.listCategoryHc = payload
  },
  SET_LIST_TYPE_HC (state, payload) {
    state.listTypeHc = payload
  },
  SET_HELP_CENTER (state, payload) {
    state.helpCenter = payload
  },
  SET_EXPORT_HELP_CENTER (state, payload) {
    state.exportHelpCenter = payload
  },
  SET_DETAIL_HELP_CENTER (state, payload) {
    state.detail_help_center = payload
  },
  SET_LIST_BULK_UPLOAD (state, payload) {
    state.bulk_uploads = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListCategoryHc (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_CATEGORY_HC, payload)
      context.commit('SET_LIST_CATEGORY_HC', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListTypeHc (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_TYPE_HC, payload)
      context.commit('SET_LIST_TYPE_HC', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListHelpCenter (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_HELP_CENTER, payload)
      context.commit('SET_HELP_CENTER', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'HelpCenter', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getExportHelpCenter (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.EXPORT_HELP_CENTER, payload)
      context.commit('SET_EXPORT_HELP_CENTER', response.data)
      context.commit('SET_META_FILTER', payload)
      exportCsv(response.data, "export_help_center")
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailHelpCenter (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.DETAIL_HELP_CENTER(payload))
      context.commit('SET_DETAIL_HELP_CENTER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async solvedHelpCenter (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.SOLVED_HELP_CENTER(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async solvedHelpCenterBulk (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.SOLVED_HELP_CENTER_BULK, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async updateSolveByEmail (context, payload) {
    try {
      const response = await apiPostAuth(API_CONSUMER.UPDATE_SOLVE_BY_EMAIL(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async bulkUploadHelpCenter(context, payload) {
    try {
      const response = await apiPostAuth(API_CONSUMER.BULK_UPLOAD_CONSUMER_HC, payload)
      context.commit('SET_LIST_BULK_UPLOAD', response.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_category_hc (state) {
    return state.listCategoryHc
  },
  list_type_hc (state) {
    return state.listTypeHc
  },
  list_help_center (state) {
    return state.helpCenter
  },
  list_bulk_uploads (state) {
    return state.bulk_uploads
  },
  export_help_center (state) {
    return state.exportHelpCenter
  },
  detail_help_center (state) {
    return state.detail_help_center
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
