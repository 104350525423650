import { apiGetAuth, apiPutAuth, apiPostAuth, apiDeleteAuth } from '@/utils/api'
import { API_HELPDESK } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    mappingEmails: [],
    mappingEmailDetail: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_MAPPING_EMAIL(state, payload) {
    state.mappingEmails = payload
  },
  SET_MAPPING_EMAIL_DETAIL(state, payload) {
    state.mappingEmailDetail = payload
  },
  SET_DATA_MODAL_ADD_EDIT(state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD(state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async listMappingEmail(_, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_MAPPING_EMAIL, payload)
      
      const mappedData = response.data.data.data.map(item => ({
        id: item.id,
        user_id: item.user.id,
        email: item.user.email,
        name: item.user.name,
        username: item.user.username,
        parent_id: item.parent_id
      }))
      return mappedData
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createMappingEmail(context, payload) {
    try {
      const response = await apiPostAuth(API_HELPDESK.CREATE_MAPPING_EMAIL, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getMappingEmailDetail(context, id) {
    try {
      const response = await apiGetAuth(API_HELPDESK.DETAIL_MAPPING_EMAIL(id))
      context.commit('SET_MAPPING_EMAIL_DETAIL', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateMappingEmail(context, payload) {
    try {
      const response = await apiPutAuth(API_HELPDESK.UPDATE_MAPPING_EMAIL(payload.id), payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async deleteMappingEmailDetail(context, id) {
    try {
      const response = await apiDeleteAuth(API_HELPDESK.DELETE_MAPPING_EMAIL(id))
      context.commit('SET_MAPPING_EMAIL_DETAIL', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async listMappingEmailUser(_, payload) {
    try {
      const response = await apiGetAuth(API_HELPDESK.LIST_MAPPING_EMAIL_USER+`?search=${payload}`)
      return response.data.data.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  mappingEmails(state) {
    return state.mappingEmails
  },
  mappingEmailDetail(state) {
    return state.mappingEmailDetail
  },
  get_data_modal_add_edit(state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard(state) {
    return state.toggle_modal_add_edit_discard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
