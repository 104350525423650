import { apiGetAuth, apiPutAuth } from '@/utils/api'
import { API_MASTER_DATA } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    privacy_policy: { data: {} },
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_PRIVACY_POLICY(state, payload) {
    state.privacy_policy = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getListPrivacyPolicy (context) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.PRIVACY_POLICY)
      context.commit('SET_PRIVACY_POLICY', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updatePrivacyPolicy (context, payload) {
    try {
      const response = await apiPutAuth(API_MASTER_DATA.PRIVACY_POLICY + `?language_type=${payload.type}`, payload.reqBody)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_privacy_policy (state) {
    return state.privacy_policy
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
