import { createApp } from "vue"
import App from './App'
import router from './router'
import store from "./store"
import interceptorsSetup from '@/configs/axios'
// https://vue3datepicker.com/
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
// https://github.com/shentao/vue-multiselect/tree/next
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import VueCountdown from '@chenfengyuan/vue-countdown'

/* import { ValidationProvider } from 'vee-validate' */

interceptorsSetup()

const app = createApp(App).use(store).use(router)
app.component('Datepicker', Datepicker)
app.component('VueMultiselect', VueMultiselect)
app.component(VueCountdown.name, VueCountdown)

/* app.component('ValidationProvider', ValidationProvider) */
app.mount("#app")
