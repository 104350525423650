import { apiGetAuth, apiPutAuth } from '@/utils/api'
import { API_DIGITAL_STAMP } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    howto_redeem: { data: {} },
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingButton: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_HOW_TO_REDEEM(state, payload) {
    state.howto_redeem = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getListHowtoRedeem (context) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.HOWTO_REDEEM)
      context.commit('SET_HOW_TO_REDEEM', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateHowtoRedeem (context, payload) {
    try {
      const response = await apiPutAuth(API_DIGITAL_STAMP.HOWTO_REDEEM + `?language_type=${payload.type}`, payload.reqBody)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_howto_redeem (state) {
    return state.howto_redeem
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
