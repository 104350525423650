import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth} from '@/utils/api'
import { API_MASTER_DATA } from '@/utils/api-url'
import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    group_stores: { data: [] },
    detail_group_store: { data: {}},
    list_store: { data: []},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_ALL_GROUP_STORE (state, payload) {
    state.group_stores = payload
  },
  SET_GROUP_STORE (state, payload) {
    state.group_stores = payload
  },
  SET_DETAIL_GROUP_STORE (state, payload) {
    state.detail_group_store = payload
  },
  SET_LIST_STORE (state, payload) {
    state.list_store = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListAllGroupStore (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.LIST_GROUP_STORE, payload)
      context.commit('SET_ALL_GROUP_STORE', response.data.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListGroupStore (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.LIST_GROUP_STORE, payload)
      context.commit('SET_GROUP_STORE', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'Groupstore', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailGroupStore (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.DETAIL_GROUP_STORE(payload))
      context.commit('SET_DETAIL_GROUP_STORE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStoreFromGroupStore (context, payload) {
    try {
      const response = await apiPostAuth(API_MASTER_DATA.LIST_STORE_FROM_GROUP_STORE(!!payload.param.search || !!payload.param.status ? `search=${payload.param.search || ''}&status=${payload.param.status || ''}` : ''), payload.data)
      context.commit('SET_LIST_STORE', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'GroupstoreDetail', query: !!payload.param.search || !!payload.param.status ? payload.param : ''})
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStoreAll (context) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.LIST_STORE, {is_all: true})
      context.commit('SET_LIST_STORE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createGroupStore (context, payload) {
    try {
      const response = await apiPostAuth(API_MASTER_DATA.CREATE_GROUP_STORE, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async updateGroupStore (context, payload) {
    try {
      const response = await apiPutAuth(API_MASTER_DATA.UPDATE_GROUP_STORE(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  },
  async deleteGroupStore (context, payload) {
    try {
      const response = await apiDeleteAuth(API_MASTER_DATA.DELETE_GROUP_STORE(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_group_store (state) {
    return state.group_stores
  },
  detail_group_store (state) {
    return state.detail_group_store
  },
  list_store (state) {
    return state.list_store
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
